import AOS from "aos";
import "aos/dist/aos.css";
function Footer() {
  return (
    <>
      <div
        className="container text-center py-10 mb-5 mt-5"
        data-aos="fade-down"
        data-aos-delay="1000"
      >
        <ul className="list-inline mb-3">
          <li className="list-inline-item">
            <a
              className="btn btn-soft-secondary text-white btn-sm btn-icon-rounded-circle"
              href="https://discord.gg/bgkmctyE"
              target="_blank"
              title="Discord"
            >
              <i className="fa-brands fa-discord"></i>
            </a>
          </li>
        </ul>
        <p className="d-inline-block mb-0">
          © 2024 PB Resugence Private Server. All rights reserved. <br />
          Web Developed by{" "}
          <a
            className="text-decoration-none"
            href="https://instagram.com/iqbalbilalismail"
            target="_blank"
          >
            Cetta20
          </a>
        </p>
      </div>
    </>
  );
}

export default Footer;
