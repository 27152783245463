import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
function HomeContent() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <>
      <div
        className="overflow-hidden gradient-radial-sm-primary"
        data-aos="fade-up"
        data-aos-delay="500"
      >
        <div className="container-lg h-100">
          <div className="d-flex flex-column justify-content-center align-items-center text-center vh-100">
            <div className="d-flex flex-column justify-content-center align-items-center vh-100 text-center">
              <p className="fw-bold fs-1">Welcome Back To</p>
              <h3 className="fs-1 text-uppercase">
                <span className="text-danger fs-1">Point Blank</span> Resugence
              </h3>
              <h4 className="fs-2 text-uppercase mb-5">Private Server</h4>
              <a className="btn btn-danger" href="/download">
                Download Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeContent;
