import React, { useEffect, useState } from "react";
import AOS from "aos";
import { toast, Bounce } from "react-toastify";
import "aos/dist/aos.css";
function LeaderboardContent() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  const [players, setPlayers] = useState([]);
  const [clans, setClans] = useState([]);

  useEffect(() => {
    fetch("/api/v1/leaderboard/player")
      .then((response) => response.json())
      .then((data) => setPlayers(data.data))
      .catch(() => {
        toast.error(`Error while fetching top leaderboard player.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      });
  }, []);

  useEffect(() => {
    fetch("/api/v1/leaderboard/clan")
      .then((response) => response.json())
      .then((data) => setClans(data.data))
      .catch(() => {
        toast.error(`Error while fetching top leaderboard clan.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      });
  }, []);
  return (
    <>
      <div className="container mt-5">
        <h2
          className="text-uppercase mb-3"
          data-aos="fade-right"
          data-aos-delay="200"
        >
          Leaderboard
        </h2>
        <div
          className="d-flex justify-content-center mb-5"
          data-aos="fade-down"
        >
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active btn-tabs-custom"
                id="player-tab"
                data-bs-toggle="tab"
                data-bs-target="#player-tab-pane"
                type="button"
                role="tab"
                aria-controls="player-tab-pane"
                aria-selected="true"
              >
                Player
              </button>
            </li>
            <li class="nav-item btn-tabs-custom" role="presentation">
              <button
                class="nav-link"
                id="clan-tab"
                data-bs-toggle="tab"
                data-bs-target="#clan-tab-pane"
                type="button"
                role="tab"
                aria-controls="clan-tab-pane"
                aria-selected="false"
              >
                Clan
              </button>
            </li>
          </ul>
        </div>
        <div class="tab-content" id="myTabContent" data-aos="fade-up">
          <div
            class="tab-pane fade show active"
            id="player-tab-pane"
            role="tabpanel"
            aria-labelledby="player-tab"
            tabindex="0"
          >
            <div className="table-responsive">
              <div className="card mb-3">
                <div className="card-body">
                  <table className="table table-hover">
                    <thead className="bg-primary">
                      <tr className="text-center text-uppercase">
                        <th>Ranking</th>
                        <th>Nickname</th>
                        <th>Exp</th>
                      </tr>
                    </thead>
                    <tbody>
                      {players.map((player) => (
                        <tr key={player.rank} class="text-center">
                          <td>{player.rank}</td>
                          <td>{player.player_name}</td>
                          <td>{player.exp.toLocaleString("de-DE")}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="clan-tab-pane"
            role="tabpanel"
            aria-labelledby="clan-tab"
            tabindex="0"
          >
            <div className="table-responsive">
              <div className="card mb-3">
                <div className="card-body">
                  <table className="table table-hover">
                    <thead className="bg-primary">
                      <tr className="text-center text-uppercase">
                        <th>Ranking</th>
                        <th>Clan</th>
                        <th>Exp</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clans.map((clan) => (
                        <tr key={clan.rank} class="text-center">
                          <td>{clan.rank}</td>
                          <td>{clan.clan_name}</td>
                          <td>{clan.clan_exp.toLocaleString("de-DE")}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeaderboardContent;
