import React, { useEffect, useState } from "react";
import { toast, Bounce } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
function Header() {
  const username = localStorage.getItem("username");
  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const onProgress = () => {
    toast.info("This feature is coming soon.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    });
  };

  useEffect(() => {
    if (username) {
      axios
        .post("/api/v1/user/info", { username })
        .then((response) => {
          if (response.data.data) {
            setUserInfo(response.data.data);
          } else {
            toast.error("User data not found.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
            });
          }
        })
        .catch((error) => {
          toast.error("Error fetching user info: " + error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        });
    }
  }, [username]);

  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-custom shadow">
        <div className="container">
          <a className="navbar-brand" href="/user">
            PB Resugence
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <div class="dropdown">
                  <button
                    class="btn bg-secondary dropdown-toggle transparent"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    User
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        className={`dropdown-item ${
                          location.pathname === "/user" ? "text-warning" : ""
                        }`}
                        aria-current={
                          location.pathname === "/user" ? "page" : undefined
                        }
                        href="/user"
                      >
                        Profile
                      </a>
                    </li>
                    <li>
                      <a
                        className={`dropdown-item ${
                          location.pathname === "/user/inventory"
                            ? "text-warning"
                            : ""
                        }`}
                        aria-current={
                          location.pathname === "/user/inventory"
                            ? "page"
                            : undefined
                        }
                        // href="/user/inventory"
                        onClick={onProgress}
                      >
                        Inventory
                      </a>
                    </li>
                    <li>
                      <a
                        className={`dropdown-item ${
                          location.pathname === "/user/setting"
                            ? "text-warning"
                            : ""
                        }`}
                        aria-current={
                          location.pathname === "/user/setting"
                            ? "page"
                            : undefined
                        }
                        // href="/user/setting"
                        onClick={onProgress}
                      >
                        Setting
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <div className="dropdown">
              <a
                className="navbar-dropdown-account-wrapper px-2 py-2"
                href="#"
                id="accountNavbarDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
                data-bs-dropdown-animation=""
              >
                <img
                  width="24"
                  className="me-2"
                  src={`https://fake-games.com/assets/img/rank/player/${
                    userInfo?.rank || "default"
                  }.gif`}
                />
                <span>{username}</span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
                aria-labelledby="accountNavbarDropdown"
              >
                <div className="dropdown-item-text">
                  <div className="d-flex align-items-center">
                    <img
                      width="24"
                      src={`https://fake-games.com/assets/img/rank/player/${
                        userInfo?.rank || "default"
                      }.gif`}
                      alt="User Rank"
                    />
                    <div className="flex-grow-1 ms-3">
                      <h5 className="mb-0 fs-6 text-warning">
                        {userInfo?.player_name || "New User"}
                      </h5>
                      <p className="card-text text-body">{username}</p>
                    </div>
                  </div>
                </div>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="/user/logout">
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className="container mt-5">
        <div className="row justify-content-center align-items-center">
          <div className="col" data-aos="fade-right" data-aos-delay="700">
            <h3 className="page-header-title">Player</h3>
          </div>
          <div
            className="col-auto d-flex flex-row flex-wrap align-items-center justify-content-center gap-2"
            data-aos="fade-left"
            data-aos-delay="700"
          >
            <div className="d-flex flex-row flex-wrap align-items-center justify-content-center gap-2">
              <div class="badge bg-secondary px-3 py-1">
                <p class="mb-1">Point</p>
                <span class="fs-5 text-white text-left">
                  {userInfo?.gp.toLocaleString("de-DE")}
                </span>
              </div>
              <div class="badge bg-success px-3 py-1">
                <p class="mb-1">Cash</p>
                <span class="fs-5 text-success text-left">
                  {userInfo?.money.toLocaleString("de-DE")}
                </span>
              </div>
              <div class="badge bg-warning px-3 py-1">
                <p class="mb-1">EXP</p>
                <span class="fs-5 text-warning text-left">
                  {userInfo?.exp.toLocaleString("de-DE")}
                </span>
              </div>
              <div class="badge bg-danger px-3 py-1">
                <p class="mb-1">UID</p>
                <span class="fs-5 text-warning text-left">
                  {userInfo?.player_id}
                </span>
              </div>
            </div>
            <div class="d-flex flex-row flex-wrap align-items-center justify-content-center gap-2">
              <a onClick={onProgress} class="btn btn-dark fs-5">
                <i class="fa-solid fa-shop"></i> Shop
              </a>
              <a onClick={onProgress} class="btn btn-primary fs-5">
                <i class="fa-solid fa-dollar-sign"></i> Top Up
              </a>
              <button
                type="button"
                class="btnShowRedeemCode btn btn-danger fs-5"
                onClick={onProgress}
              >
                <i class="fa-solid fa-pen"></i> Redeem Code
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
