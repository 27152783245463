import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../layouts/header";
import Info from "../layouts/info";
import Footer from "../layouts/footer";
function Profile() {
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem("username");

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/auth/login");
    }
  }, [isAuthenticated, navigate]);
  return (
    <>
      <Header />
      <Info />
      <Footer />
    </>
  );
}

export default Profile;
