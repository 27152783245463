import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../layouts/navbar";
import RegisterContent from "./register_content";
import Footer from "../layouts/footer";
function Register() {
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem("username");

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/user");
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <Navbar />
      <RegisterContent />
      <Footer />
    </>
  );
}

export default Register;
