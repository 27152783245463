import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../layouts/navbar";
import LeaderboardContent from "./leaderboard_content";
import Footer from "../layouts/footer";
function Leaderboard() {
  return (
    <>
      <Navbar />
      <LeaderboardContent />
      <Footer />
    </>
  );
}

export default Leaderboard;
