import React from "react";
import { useNavigate } from "react-router-dom";
import { toast, Bounce } from "react-toastify";

function Logout() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("username");
    sessionStorage.removeItem("username");

    toast.success("You have been logged out successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    });

    navigate("/auth/login");
  };

  React.useEffect(() => {
    handleLogout();
  }, [navigate]);

  return (
    <div>
      <p>Logging out...</p>
    </div>
  );
}

export default Logout;
