import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
function DownloadContent() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <>
      <div className="container mt-5">
        <h2
          className="text-uppercase mb-3"
          data-aos="fade-right"
          data-aos-delay="200"
        >
          Download
        </h2>
        <div className="row mb-3">
          <h3 className="mb-3" data-aos="fade-right" data-aos-delay="200">
            Client & Patch
          </h3>
          <div
            className="col-lg-6 mb-3"
            data-aos="fade-right"
            data-aos-delay="200"
          >
            <div className="card">
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <p className="card-header-title">Full Client</p>
                  <span className="card-header-span">
                    Version 3.16: | 3.5 GB
                  </span>
                </div>
              </div>
              <div className="card-body">
                <div className="d-flex justify-content-center">
                  <a className="btn btn-outline-primary text-uppercase me-5">
                    <p className="fs-6">Link 1</p>
                    <p className="fw-bold">Download</p>
                  </a>
                  <a className="btn btn-outline-success text-uppercase">
                    <p class="fs-6">Link 2</p>
                    <p className="fw-bold">Download</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 mb-3"
            data-aos="fade-left"
            data-aos-delay="200"
          >
            <div className="card">
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <p className="card-header-title">Patch</p>
                  <span className="card-header-span">
                    Version: 3.16 | 200 MB
                  </span>
                </div>
              </div>
              <div className="card-body">
                <div className="d-flex justify-content-center">
                  <a className="btn btn-outline-primary text-uppercase">
                    <p className="fs-6">Link 1</p>
                    <p className="fw-bold">Download</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 justify-content-center">
          <h3 className="mb-3" data-aos="fade-right" data-aos-delay="500">
            Misc
          </h3>
          <div
            className="col-lg-6 mb-3"
            data-aos="fade-right"
            data-aos-delay="500"
          >
            <div className="card">
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <p className="card-header-title">
                    MultiPack Visual C++ Installer
                  </p>
                  <span className="card-header-span">
                    Version: MPVCI v2.7 | 86 MB
                  </span>
                </div>
              </div>
              <div className="card-body">
                <div className="d-flex justify-content-center">
                  <a className="btn btn-outline-danger text-uppercase">
                    <p className="fs-6">Link 1</p>
                    <p className="fw-bold">Download</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 mb-3"
            data-aos="fade-left"
            data-aos-delay="500"
          >
            <div className="card">
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <p className="card-header-title">DirectX 9.0c</p>
                  <span className="card-header-span">Version: 9.0 | 96 MB</span>
                </div>
              </div>
              <div className="card-body">
                <div className="d-flex justify-content-center">
                  <a className="btn btn-outline-info text-uppercase">
                    <p className="fs-6">Link 1</p>
                    <p className="fw-bold">Download</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 mb-3"
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <div className="card">
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <p className="card-header-title">NET Framework 4.7.2</p>
                  <span className="card-header-span">
                    Version: 4.7.2 | 82 MB
                  </span>
                </div>
              </div>
              <div className="card-body">
                <div className="d-flex justify-content-center">
                  <a className="btn btn-outline-danger text-uppercase">
                    <p className="fs-6">Link 1</p>
                    <p className="fw-bold">Download</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2 className="text-uppercase mb-3 mt-5" data-aos="fade-right">
          <span className="text-danger">System</span> Requirements
        </h2>
        <div className="card" data-aos="fade-left">
          <div className="card-body text-center">
            <p className="text-primary">Recommended System</p>
            <p>
              <span className="text-primary">Windows:</span> Windows 7 or higher
            </p>
            <p>
              <span className="text-primary">Processor:</span> Intel Dual Core
              2.8GHZ | AMD Athlon II X2 250
            </p>
            <p>
              <span className="text-primary">VGA:</span> NVIDIA 9600GT (512MB),
              Radeon HD5570 or higher
            </p>
            <p>
              <span className="text-primary">RAM:</span> 2GB or higher
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default DownloadContent;
