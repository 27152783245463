import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { toast, Bounce } from "react-toastify";
import axios from "axios";
function RegisterContent() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirm_password: "",
    pin: "",
    confirm_pin: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setStatusMessage("");

    axios
      .post("/api/v1/auth/register", formData)
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        } else {
          toast.warning(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        }
      })
      .catch((error) => {
        toast.error(`An error occured during proccesing data.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <div
        className="overflow-hidden gradient-radial-sm-primary"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        <div className="container-lg h-100 mt-5">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="text-center fw-bold text-uppercase mb-3">
                    Register Account
                  </h4>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group mb-3">
                      <label className="form-label" htmlFor="username">
                        Username
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="username"
                        placeholder="Username"
                        minLength={6}
                        maxLength={12}
                        value={formData.username}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label" htmlFor="email">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                        minLength={6}
                        maxLength={50}
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group mb-3">
                          <label className="form-label" htmlFor="password">
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            placeholder="Password"
                            minLength={6}
                            maxLength={12}
                            value={formData.password}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group mb-3">
                          <label
                            className="form-label"
                            htmlFor="confirm_password"
                          >
                            Confirmation Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="confirm_password"
                            placeholder="Confirmation Password"
                            minLength={6}
                            maxLength={12}
                            value={formData.confirm_password}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group mb-3">
                          <label className="form-label" htmlFor="pin">
                            PIN
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="pin"
                            placeholder="PIN"
                            maxLength={6}
                            minLength={6}
                            value={formData.pin}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="form-group mb-3">
                          <label className="form-label" htmlFor="confirm_pin">
                            Confirmation PIN
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="confirm_pin"
                            placeholder="Confirmation PIN"
                            maxLength={6}
                            minLength={6}
                            value={formData.confirm_pin}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <i className="text-warning">
                      PIN is a security code used to secure your account. This
                      PIN cannot be changed, so make sure you remember it well.
                    </i>
                    <button
                      type="submit"
                      className="btn btn-danger w-100 mt-3"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegisterContent;
