import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem("username");
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-custom shadow">
        <div className="container">
          <a className="navbar-brand" href="/">
            PB Resugence
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    location.pathname === "/" ? "text-warning" : ""
                  }`}
                  aria-current={location.pathname === "/" ? "page" : undefined}
                  href="/"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    location.pathname === "/download" ? "text-warning" : ""
                  }`}
                  aria-current={
                    location.pathname === "/download" ? "page" : undefined
                  }
                  href="/download"
                >
                  Download
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    location.pathname === "/leaderboard" ? "text-warning" : ""
                  }`}
                  aria-current={
                    location.pathname === "/leaderboard" ? "page" : undefined
                  }
                  href="/leaderboard"
                >
                  Leaderboard
                </a>
              </li>
            </ul>

            <div>
              {!isAuthenticated ? (
                <>
                  <a className="btn btn-danger me-2" href="/auth/login">
                    Login
                  </a>
                  <a className="btn btn-success" href="/auth/register">
                    Register
                  </a>
                </>
              ) : (
                <a className="btn btn-secondary w-100 me-2" href="/user">
                  Player Panel
                </a>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
