import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { toast, Bounce } from "react-toastify";
import axios from "axios";
function LoginContent() {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setStatusMessage("");

    axios
      .post("/api/v1/auth/login", formData)
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          localStorage.setItem("username", response.data.message);
          toast.success("Login successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
          setIsSubmitting(false);
          const timer = setTimeout(() => {
            navigate("/user", { replace: true });
            window.location.reload();
          }, 500);
        } else {
          toast.warning(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        }
      })
      .catch((error) => {
        toast.error(`An error occured during proccesing data.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <div
        className="overflow-hidden gradient-radial-sm-primary"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        <div className="container-lg h-100 mt-5">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="text-center fw-bold text-uppercase mb-3">
                    Login Account
                  </h4>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group mb-3">
                      <label className="form-label" htmlFor="username">
                        Username
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="username"
                        placeholder="Username"
                        minLength={6}
                        maxLength={12}
                        value={formData.username}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label" htmlFor="password">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="Password"
                        minLength={6}
                        maxLength={12}
                        value={formData.password}
                        onChange={handleChange}
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-danger w-100 mt-3"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginContent;
