import React, { useEffect, useState } from "react";
import { toast, Bounce } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
function Info() {
  const username = localStorage.getItem("username");
  const [userInfo, setUserInfo] = useState(null);
  const [rankInfo, setRankInfo] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (username) {
      axios
        .post("/api/v1/user/info", { username })
        .then((response) => {
          if (response.data.data) {
            setUserInfo(response.data.data);
            setRankInfo(response.data.ranks);
          } else {
            toast.error("User data not found.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
            });
          }
        })
        .catch((error) => {
          toast.error("Error fetching user info: " + error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
          });
        });
    }
  }, [username]);

  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <>
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <h2 className="mb-3" data-aos-delay="300" data-aos="fade-right">
              Profile
            </h2>
            <div
              className="alert alert-danger mb-3"
              data-aos-delay="300"
              data-aos="fade-left"
            >
              <i class="fa-solid fa-circle-info"></i> Never give your account
              data to anyone, including PB Resugence staff. We will not be
              responsible for any negligence on your part.
            </div>
            <div
              class="col-12 mb-3 mb-5"
              data-aos-delay="700"
              data-aos="fade-up"
            >
              <div class="card card-hover-shadow">
                <div class="card-body">
                  <div class="d-flex flex-wrap gap-3 gap-md-0 justify-content-between">
                    <div class="d-flex flex-grow-1 align-items-center">
                      <img
                        class="me-2"
                        width="24"
                        height="24"
                        src={`https://fake-games.com/assets/img/rank/player/${userInfo?.rank}.gif`}
                      />
                      <p class="m-0 fw-bold text-warning">
                        {userInfo?.player_name || "New User"}
                      </p>
                    </div>
                    <div class="d-flex flex-grow-1 align-items-center justify-content-between justify-content-md-start">
                      <p class="m-0 me-4">EXP: </p>
                      <div class="d-block">
                        <p className="m-0 mb-1">
                          {userInfo?.exp.toLocaleString("de-DE")}/
                          {rankInfo?.all_exp.toLocaleString("de-DE")}
                        </p>
                      </div>
                    </div>
                    <div class="flex-grow-1" style={{ width: "10rem;" }}>
                      <div class="d-flex justify-content-between align-items-center">
                        <p class="m-0">Point:</p>
                        <p class="m-0">
                          {userInfo?.gp.toLocaleString("de-DE")}
                        </p>
                      </div>
                      <div class="d-flex justify-content-between align-items-center">
                        <p class="m-0">Cash:</p>
                        <p class="m-0">
                          {userInfo?.money.toLocaleString("de-DE")}
                        </p>
                      </div>
                      <div class="d-flex justify-content-between align-items-center">
                        <p class="m-0">Coin:</p>
                        <p class="m-0">
                          {userInfo?.coin.toLocaleString("de-DE")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-12 mb-3"
              data-aos-delay="700"
              data-aos="fade-down"
            >
              <div className="row justify-content-start">
                <div className="col-sm-6 col-lg-3 mb-3">
                  <div class="card h-100 card-hover-shadow">
                    <div class="card-body">
                      <h6 class="card-subtitle text-danger">Match</h6>
                      <div class="row align-items-center gx-2">
                        <div class="col-6">
                          <h2 class="card-title text-inherit mt-3">
                            {userInfo?.fights}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3 mb-3">
                  <div class="card h-100 card-hover-shadow">
                    <div class="card-body">
                      <h6 class="card-subtitle text-danger">Win</h6>
                      <div class="row align-items-center gx-2">
                        <div class="col-6">
                          <h2 class="card-title text-inherit mt-3">
                            {userInfo?.fights_win}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3 mb-3">
                  <div class="card h-100 card-hover-shadow">
                    <div class="card-body">
                      <h6 class="card-subtitle text-danger">Lose</h6>
                      <div class="row align-items-center gx-2">
                        <div class="col-6">
                          <h2 class="card-title text-inherit mt-3">
                            {userInfo?.fights_lost}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3 mb-3">
                  <div class="card h-100 card-hover-shadow">
                    <div class="card-body">
                      <h6 class="card-subtitle text-danger">Kill</h6>
                      <div class="row align-items-center gx-2">
                        <div class="col-6">
                          <h2 class="card-title text-inherit mt-3">
                            {userInfo?.kills_count}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3 mb-3">
                  <div class="card h-100 card-hover-shadow">
                    <div class="card-body">
                      <h6 class="card-subtitle text-danger">Death</h6>
                      <div class="row align-items-center gx-2">
                        <div class="col-6">
                          <h2 class="card-title text-inherit mt-3">
                            {userInfo?.deaths_count}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3 mb-3">
                  <div class="card h-100 card-hover-shadow">
                    <div class="card-body">
                      <h6 class="card-subtitle text-danger">Headshot</h6>
                      <div class="row align-items-center gx-2">
                        <div class="col-6">
                          <h2 class="card-title text-inherit mt-3">
                            {userInfo?.headshots_count}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3 mb-3">
                  <div class="card h-100 card-hover-shadow">
                    <div class="card-body">
                      <h6 class="card-subtitle text-danger">Escape</h6>
                      <div class="row align-items-center gx-2">
                        <div class="col-6">
                          <h2 class="card-title text-inherit mt-3">
                            {userInfo?.escapes}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Info;
