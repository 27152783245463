import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./security/protectedRoute";
import Home from "./pages/home";
import Download from "./pages/download";
import Leaderboard from "./pages/leaderboard";
import Register from "./pages/register";
import Login from "./pages/login";
import User from "./user/profile";
import Logout from "./user/logout";
function App() {
  return (
    // Add return statement here
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/download" element={<Download />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route path="/auth/register" element={<Register />} />
        <Route path="/auth/login" element={<Login />} />
        <Route path="/user" element={<User title="profile" />} />
        <Route path="/user/logout" element={<Logout title="logout" />} />
        {/* Uncomment and modify these lines as needed */}
        {/* <Route path="/about" element={<About />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="/user" element={<ProtectedRoute><UserPage /></ProtectedRoute>} /> */}
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
