import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../layouts/navbar";
import LoginContent from "./login_content";
import Footer from "../layouts/footer";
function Login() {
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem("username");

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/user");
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <Navbar />
      <LoginContent />
      <Footer />
    </>
  );
}

export default Login;
